body {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none !important;
}

root {
  display: block;
}

.main {
  width: 100%;
}

::placeholder {
  color: rgb(200, 200, 200);
}

.swiper-slide {
  img {
    width: 0;
  }
}

.swiper-slide {
  &.swiper-slide-active {
    img {
      width: fit-content;
    }
  }
}

// fonts

@font-face {
  font-family: "GilroyExtraBold";
  src: local("GilroyExtraBold"),
  url('./assets/fonts/Gilroy-ExtraBold.ttf') format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
  url('./assets/fonts/Gilroy-Bold.ttf') format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
  url('./assets/fonts/Gilroy-Regular.ttf') format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "GilroySemiBold";
  src: local("GilroySemiBold"),
  url('./assets/fonts/Gilroy-SemiBold.ttf') format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"),
  url('./assets/fonts/Gilroy-Medium.ttf') format("truetype");
  font-weight: regular;
}
